@import url('https://fonts.googleapis.com/css?family=Encode+Sans+Expanded:300,400');
@import '~swiper/swiper-bundle.min.css';

$bleu : #446CB3;
$rouge: #EC6F62;
$font1 : 'Encode Sans Expanded', sans-serif;

$ease-out-quint:cubic-bezier(0.230, 1.000, 0.320, 1.000);
$ease-in-quint:cubic-bezier(0.755, 0.050, 0.855, 0.060);
$screen-background:#121010;

html, body{
  margin: 0;
}

h1, h2, h3, p, input, textarea, label, a, button{
  font-family: $font1;
  font-weight: 300;
  margin: 0;
}

.button{
  background-color: $rouge;
  border-radius: 60px;
  padding: 10px;
  border: none;
  outline: none;
  color: white;
}

header{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 550px;
  .header-content{
    max-width: 1050px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    padding-top: 100px;
    align-items: center;
    justify-content: space-around;

    .left{
      width: 35%;
      h1{
        font-size: 32px;
        margin-bottom: 10px;
      }
      .ligne{
        width: 150px;
        border-bottom: 5px $rouge solid;
        margin-bottom: 10px;
      }
      p{
        font-size: 18px;


      }
    }
    .right{
      img{
        width: 400px;
      }
    }
  }
}

.nav-container{
  display: flex;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
  padding: 10px;
  .logo{
    display: flex;
    align-items: center;
    img{
      width: 75px;
    }
    p{
      font-size: 30px;
      font-weight: 700;
    }
  }
  nav{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    a{
      text-decoration: none;
      color: white;
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}

.services{
  padding-top: 50px;
  padding-bottom: 50px;
  margin: 0 auto;
  h2{
    text-align: center;
    font-size: 32px;
    margin-bottom: 25px;
    font-weight: 400;
  }
  p{
    text-align: center;
    margin-bottom: 50px;
  }
  .item-container{
    display: flex;
    justify-content: space-between;
    max-width: 1000px;
    margin: 0 auto;
    .item{
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 30%;
      img{
        width: 200px;
      }
      h3{
        margin: 20px;
        font-weight: 400;
      }
      p{
        text-align: justify;
      }
    }
  }
}

@keyframes turn-on{
  0%{
    transform:scale(1,0.8) translate3d(0,0,0);
    -webkit-filter:brightness(30);
    filter:brightness(30);
    opacity:1;
  }
  3.5%{
    transform:scale(1,0.8) translate3d(0,100%,0);
  }

  3.6%{
    transform:scale(1,0.8) translate3d(0,-100%,0);
    opacity:1;
  }

  9%{
    transform:scale(1.3,0.6) translate3d(0,100%,0);
    -webkit-filter:brightness(30);
    filter:brightness(30);
    opacity:0;
  }

  11%{
    transform:scale(1,1) translate3d(0,0,0);
    -webkit-filter:contrast(0) brightness(0) ;
    filter:contrast(0) brightness(0);
    opacity:0;
  }

  75%{
    transform:scale(1,1) translate3d(0,0,0);
    -webkit-filter:contrast(1) brightness(1.2) saturate(1.3);
    filter:contrast(1) brightness(1.1) saturate(1.2);
    opacity:1;
  }

  100%{
  -webkit-filter:contrast(1) brightness(1) saturate(1);
  filter:contrast(1) brightness(1) saturate(1);
  opacity:1;
  }
}
@keyframes turn-off{
  0%{
    transform:scale(1,1.3) translate3d(0,0,0);
    -webkit-filter:brightness(1);
    filter:brightness(1);
    opacity:1;
  }
  60%{
    transform:scale(1.3,0.001) translate3d(0,0,0);
    -webkit-filter:brightness(10);
    filter:brightness(10);
  }
  100%{
    animation-timing-function:$ease-in-quint;
    transform:scale(0.000,0.0001) translate3d(0,0,0);
    -webkit-filter:brightness(50);
    filter:brightness(50);
  }
}
.close-screen{
  animation: turn-off 0.55s $ease-out-quint;
  animation-fill-mode:forwards;
}
.open-screen{
  animation: turn-on 4s linear;
  animation-fill-mode:forwards;
}



.sending-message {
  height:50px;
  width:40px;
  position:absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  .box {
    position:relative;
    height:50px;
    width:40px;
    animation: box 5s infinite linear;
  }
  .border {
    background: $bleu;
    position:absolute;
  }
  .border.one {
    height:4px;
    top:0;
    left:0;
    animation: border-one 5s infinite linear;
  }
  .border.two {
    top:0;
    right:0;
    height:100%;
    width:4px;
    animation: border-two 5s infinite linear;
  }
  .border.three {
    bottom:0;
    right:0;
    height:4px;
    width:100%;
    animation: border-three 5s infinite linear;
  }
  .border.four {
    bottom:0;
    left:0;
    height:100%;
    width:4px;
    animation: border-four 5s infinite linear;
  }
  .line {
    height:4px;
    background: $bleu;
    position:absolute;
    width:0%;
    left:25%;
  }
  .line.one {
    top:25%;
    width:0%;
    animation: line-one 5s infinite linear;
  }
  .line.two {
    top:45%;
    animation: line-two 5s infinite linear;
  }
  .line.three {
    top:65%;
    animation: line-three 5s infinite linear;
  }
}



@keyframes border-one {
  0%   {width:0;}
  10%  {width:100%;}
  100% {width:100%;}
}

@keyframes border-two {
  0%   {height:0;}
  10%  {height:0%;}
  20%  {height:100%;}
  100% {height:100%;}
}

@keyframes border-three {
  0%   {width:0;}
  20%  {width:0%;}
  30%  {width:100%;}
  100% {width:100%;}
}

@keyframes border-four {
  0%   {height:0;}
  30%  {height:0%;}
  40%  {height:100%;}
  100% {height:100%;}
}

@keyframes line-one {
  0%   {left:25%;width:0;}
  40%  {left:25%;width:0%;}
  43%  {left:25%;width:50%;}
  52%  {left:25%;width:50%;}
  54%  {left:25%;width:0% }
  55%  {right:25%;left:auto;}
  63%  {width:10%;right:25%;left:auto;}
  100% {width:10%;right:25%;left:auto;}
}

@keyframes line-two {
  0%   {width:0;}
  42%  {width:0%;}
  45%  {width:50%;}
  53%  {width:50%;}
  54%  {width:0% }
  60%  {width:50%}
  100% {width:50%;}
}

@keyframes line-three {
  0%   {width:0;}
  45%  {width:0%;}
  48%  {width:50%;}
  51%  {width:50%;}
  52%  {width:0% }
  100% {width:0%;}
}

@keyframes box {
  0%   {opacity:1;margin-left:0px;height:50px;width:40px;}
  55%  {margin-left:0px;height:50px;width:40px;}
  60%  {margin-left:0px;height:35px;width:50px;}
  74%  {msthin-left:0;}
  80%  {margin-left:-50px;opacity:1;}
  90% {height:35px;width:50px;margin-left:50px;opacity:0;}
  100% {opacity:0;}
}

.contact{
  background-size: cover;

  .contact-container{
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .left{
      background-color: rgba(#fefeff,0.90);
      padding: 40px;
      box-shadow: #444 0 0 10px -1px ;
      display: block;
      text-decoration: none;
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: relative;
      .titre{
        position: absolute;
        top: 0;
        transform: translateX(-0%) translateY(-100%);
        background-color: rgba( $bleu, 0.8);
        color: white;
        padding: 10px;
        left: 0;
        transform-origin: 0 0;
      }
      span{
        color: black;
        font-size: 20px;
        font-weight: 400;

      }
      img{
        width: 25px;
        margin-right: 20px;
      }
    }
    .form{

      width: 300px;
      height: 600px;
      background-size: contain;
      background-repeat: no-repeat;
      padding-top: 70px;
      padding-left: 20px;
      padding-right: 21px;
      padding-bottom: 70px;
      box-sizing: border-box;
      position:relative;
      .wrapper{
        height: 100%;
        overflow: hidden;
        position: relative;
      }
      form{
        display: flex;
        flex-direction: column;
        height: 100%;
        position:relative;
        background-color: #dededf;
        box-sizing: border-box;
        padding-top: 20px;
        h2{
          text-align: center;
          background-color: $bleu;
          color: white;
          padding: 5px;
        }
        .input-container{
          background-color: #fefeff ;
          padding: 10px;
          margin-bottom: 10px;
          padding-top: 20px;
        }
        .input{
          position:relative;
          margin-bottom: 20px;
          padding-top: 10px;
          border-bottom: 1px #444444 solid;
        }
        .error{
          font-size: 12px;
          color: $rouge;
          text-align: center;
        }
        label{
          display: block;
          position: absolute;
          top: 10px;
          left: 5px;
          transition: all 0.3s ease;
          color: #aaaaab;
          font-size: 14px;
          display: flex;
          align-items: center;
          img{
            width: 20px;
            margin-right: 10px;
          }
        }
        input{
          width: 100%;
          box-sizing: border-box;
          background-color: transparent;
          border:none;
          padding: 5px;
          outline: none;
          margin-bottom: 5px;

          padding-bottom: 10px;
        }
        input:focus + label , .not-empty{
          top: -10px;
          left: 0px;
          font-size: 14px;
        }
        textarea{
          width: 100%;
          box-sizing: border-box;
          resize: none;
          outline: none;
          padding: 5px;
          height: 150px;
          background-color: transparent;
          border:none;
          &::placeholder{
            color: #aaaaab;
          }
        }
        button{
          width: 90%;
          margin: 0 auto;
          border-radius: 5px;
          outline: none;
          border: none;
          padding: 5px;
          background-color: $bleu;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s ease;
          &:hover{
            background-color: #557bbe;
          }
          img{
            width: 20px;
            margin-right: 10px;
          }
        }
      }
      .sending-message-container{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 160px;
        height: 160px;
        transform: translateX(-50%) translateY(-50%);
        background-color: rgba(#eeeeef, 0.6);
      }
      .message-sent{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(#eeeeef, 0.8);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p{
          color: $bleu;
          font-size: 24px;
          font-weight: 700;
          text-transform: uppercase;
          text-align: center;
        }
      }
    }
  }
}

.testimonial{
  background-color: #eeeeef;
  padding-top: 100px;
  padding-bottom: 100px;
  .testimonial-container{
    width: 1000px;
    margin: auto;
    h2{
      font-size: 32px;
      font-weight: 400;
      text-align: center;
    }
    .sous-titre{
      text-align: center;
      margin-top: 20px;
      margin-bottom: 50px;
    }
    .swiper-container{
      padding: 10px;
      .swiper-wrapper{

        .swiper-slide{
          background-color: #fefeff;
          padding: 20px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          box-shadow: #444 0 0 5px -2px;
          min-height: 360px;
          justify-content: space-between;
          img{
            height: 120px;
            margin-bottom: 20px;
          }
          .temoignage{
            margin-bottom: 20px;
            padding: 10px;
            text-align: justify;
            position: relative;
            font-size: 14px;

            .open-quote{
              position: absolute;
              left: 0;
              top: 0;
              width: 10px;
              height: auto;
              margin: 0;
            }
            .close-quote{
              position: absolute;
              bottom: 0;
              right: 0;
              width: 10px;
              height: auto;
              margin: 0;
              transform: scale(-1, 1);
            }
          }
          .nom{
            align-self: flex-end;
            font-style: italic;

          }
        }
      }
    }
  }

}

footer{
  background-color: #333333;
  padding: 20px;
  display: flex;
  justify-content: center;
  .logo{
    text-decoration: none;
    display: flex;
    align-items: center;
    img{
      width: 75px;
    }
    p{
      font-size: 30px;
      font-weight: 700;
      color: white;
    }
  }
}

@media screen and (max-width: 1050px){
  .services{
    width: 90%;
  }

  .contact{
    padding-bottom: 50px;
  }

  .testimonial{
    .testimonial-container{
      width: 90%;
    }
  }
}

@media screen and (max-width: 750px){
  .nav-container{

    nav{
      display: none;
    }
  }

  header{
    height: auto;
    padding-bottom: 50px;
    .header-content{
      flex-direction: column-reverse;
      padding-top: 25px;
      .left{
        width: 80%;
        h1{
          text-align: center;
        }
        .ligne{
          margin: 10px auto;
        }
        p{
          text-align: center;
        }
      }
      .right{

      }
    }
  }

  .services{
    .item-container{
      flex-direction: column;
      align-items: center;
      .item{
        width: 80%;
      }
    }
  }

  .contact{
    .contact-container{
      flex-direction: column;
      .left{
        margin-bottom: 50px;
      }
    }
  }

  .testimonial{
    padding-top: 200px;
  }
}

@media screen and(max-width: 400px){
  .nav-container{
    justify-content: center;
    .logo{
      img{
        width: 100px;
      }
      p{
        display: none;
      }
    }
  }

  header{
    .header-content{
      .left{
        width: 90%;
        h1{
          font-size: 24px;
        }
      }
      .right{
        img{
          width: 250px;
        }
      }
    }
  }
}
